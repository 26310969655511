<template>
    <div>
        <el-row>
            <el-col :span="20" :offset="2">
                <el-switch
                    v-model="recursive"
                    active-color="#13ce66"
                    inactive-color="#20a0ff"
                    :active-value="1"
                    :inactive-value="0"
                    :active-text="$t('calendar.repeatable')"
                    :inactive-text="$t('calendar.single')"
                    :width="120"
                    @change="selectChanged"
                />
            </el-col>
        </el-row>
        <el-collapse-transition>
            <el-row v-show="recursive == 1" class="mt-5">
                <el-col :span="14" :offset="2">
                    <el-form-item :label="$t('calendar.repeat_every')">
                        <el-checkbox-group v-model="repeatWeekDays" :min="1" @change="selectChanged">
                            <el-checkbox label="1">
                                {{ $t('calendar.mon') }}
                            </el-checkbox>
                            <el-checkbox label="2">
                                {{ $t('calendar.tue') }}
                            </el-checkbox>
                            <el-checkbox label="3">
                                {{ $t('calendar.wed') }}
                            </el-checkbox>
                            <el-checkbox label="4">
                                {{ $t('calendar.thu') }}
                            </el-checkbox>
                            <el-checkbox label="5">
                                {{ $t('calendar.fri') }}
                            </el-checkbox>
                            <el-checkbox label="6">
                                {{ $t('calendar.sat') }}
                            </el-checkbox>
                            <el-checkbox label="0">
                                {{ $t('calendar.sun') }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item :label="$t('calendar.repeat')">
                        <el-select v-model="repeatEveryWeek" :placeholder="$t('calendar.repeat_every')" class="dialog-select" @change="selectChanged">
                            <el-option :value="1" :label="$t('calendar.every_week')" />
                            <el-option :value="2" :label="$t('calendar.every_2_weeks')" />
                            <el-option :value="3" :label="$t('calendar.every_3_weeks')" />
                            <el-option :value="4" :label="$t('calendar.every_4_weeks')" />
                            <el-option :value="5" :label="$t('calendar.every_5_weeks')" />
                            <el-option :value="6" :label="$t('calendar.every_6_weeks')" />
                            <el-option :value="7" :label="$t('calendar.every_7_weeks')" />
                            <el-option :value="8" :label="$t('calendar.every_8_weeks')" />
                            <el-option :value="9" :label="$t('calendar.every_9_weeks')" />
                            <el-option :value="10" :label="$t('calendar.every_10_weeks')" />
                            <el-option :value="11" :label="$t('calendar.every_11_weeks')" />
                            <el-option :value="12" :label="$t('calendar.every_12_weeks')" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-collapse-transition>
    </div>
</template>
<script>
import Vue from 'vue';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition);

export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: true,
        },
    },

    data() {
        return {
            recursive:       1,
            repeatEveryWeek: 1,
            repeatWeekDays:  [],
        };
    },

    computed: {
        startDateSelected() {
            return this.$store.state.calendar.startDateSelected || this.$dayjs();
        },
    },

    watch: {
        modalVisible() {
            this.recursive = 1;
            this.repeatEveryWeek = 1;
            this.repeatWeekDays = [String(this.startDateSelected.day())];
            this.selectChanged();
        },
    },

    created() {
        this.repeatWeekDays = [String(this.startDateSelected.day())];
    },

    methods: {
        selectChanged() {
            this.$emit('recursiveChange', this.recursive);
            this.$emit('repeatWeekDaysChange', this.repeatWeekDays);
            this.$emit('repeatEveryWeekChange', this.repeatEveryWeek);
        },
    },
};
</script>
<style>
.dialog-add-event .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409EFF;
    border-color: #409EFF;
}

.dialog-add-event .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #fff;
}

.dialog-add-event .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: #409EFF;
}
</style>
